
  import { Component, Prop, Vue } from "vue-property-decorator";
  import LzModal from "./Modal.vue";
  import LzButton from "./Button.vue";

  @Component({ components: { LzModal, LzButton } })
  export default class Confirm extends Vue {
    @Prop()
    descriptionLabel?: string;

    @Prop()
    cancelLabel?: string;

    @Prop()
    confirmLabel?: string;
  }
