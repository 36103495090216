
  import { Component, Vue } from "vue-property-decorator";
  import LzButton from "@/components/Button.vue";
  import LzTable from "@/components/Table.vue";
  import LzConfirm from "@/components/Confirm.vue";
  import { namespace } from "vuex-class";
  import { apiEvents } from "../api";
  import { parseFile } from "@/utils/parseFile";
  import LzEditorInput from "@/components/EditorInput.vue";
  import {
    checkIsAlreadyPremiumSections,
    checkSubscriptionPlan,
    inValidatePremiumSectionsCache
  } from "@/utils";

  const auth = namespace("auth");

  @Component({ components: { LzButton, LzTable, LzConfirm, LzEditorInput } })
  export default class CalendarCreate extends Vue {
    loaded = false;
    showDeleteModal = false;
    isPremiumSubscription = false;
    ticketsFields = [
      {
        id: "ticketName",
        label: this.$t("calendar.create.ratesForm.name")
      },
      {
        id: "ticketAmount",
        label: this.$t("calendar.create.ratesForm.amount")
      },
      {
        id: "ticketPrice",
        label: this.$t("calendar.create.ratesForm.cost")
      },
      { id: "delete", label: this.$t("calendar.create.ratesForm.delete") }
    ];
    eventId = "";
    calendarForm = {
      imageUrlToConvert: [] as any,
      imageUrl: "",
      imagesToConvert: [] as any,
      images: [] as any,
      title: "",
      description: "",
      type: "Evento",
      mode: "Presencial",
      status: "Borrador",
      startInscriptionDate: "",
      endInscriptionDate: "",
      startEventDate: "",
      endEventDate: "",
      recurrent: false,
      frecuency: "weekly" as TEvent["data"]["frecuency"],
      link: "",
      location: "",
      ticketName: "",
      ticketAmount: 1,
      ticketPrice: 0,
      tickets: [] as {
        ticketName: string;
        ticketAmount: number;
        ticketPrice: number;
      }[],
      isPremium: false
    };

    typeOptions = {
      Evento: this.$t("calendar.create.generalForm.typeOptions.event"),
      Formación: this.$t("calendar.create.generalForm.typeOptions.training")
    };

    modeOptions = {
      Presencial: this.$t("calendar.create.generalForm.modeOptions.onsite"),
      Online: this.$t("calendar.create.generalForm.modeOptions.online")
    };

    statusOptions = {
      Borrador: this.$t("calendar.create.generalForm.statusOptions.draft"),
      Activo: this.$t("calendar.create.generalForm.statusOptions.active")
    };

    frequencyOptions = {
      Semanal: this.$t("calendar.create.dateForm.frequency.options.weekly"),
      Mensual: this.$t("calendar.create.dateForm.frequency.options.monthly"),
      Anual: this.$t("calendar.create.dateForm.frequency.options.annual")
    };

    @auth.State("id")
    public ongId!: string;

    async getEvent(eventId: string) {
      const { data: event } = await apiEvents.getEvent(eventId);

      this.calendarForm.title = event.title;
      this.calendarForm.description = event.description;
      this.calendarForm.type = event.course ? "Formación" : "Evento";
      this.calendarForm.mode = event.type;
      this.calendarForm.status = event.status;
      this.calendarForm.startInscriptionDate = event.salesStartDate;
      this.calendarForm.endInscriptionDate = event.salesEndDate;
      this.calendarForm.startEventDate = event.start_time;
      this.calendarForm.endEventDate = event.end_time;
      this.calendarForm.recurrent = event.recurrent;
      this.calendarForm.frecuency = event.frecuency;
      this.calendarForm.imageUrlToConvert = [{ url: event.imageURL }];
      this.calendarForm.link = event.location;
      this.calendarForm.location = event.location || event.link;
      this.calendarForm.isPremium = event.isPremium ?? false;

      this.calendarForm.tickets = event.EventTickets.map(ticket => ({
        ticketName: ticket.type,
        ticketAmount: ticket.stock,
        ticketPrice: ticket.price
      }));
    }

    async getEventImages(eventId: string) {
      const { data: images } = await apiEvents.getEventImages(eventId);
      this.calendarForm.imagesToConvert.length = 0;

      images.forEach(image => {
        const isMainImage = image.img_url.includes("default");
        if (isMainImage) return;
        this.calendarForm.imagesToConvert.push({ url: image.img_url });
      });
    }

    async createEvent(body: any) {
      try {
        await apiEvents.postEvent(this.ongId, body);
        this.$notify({
          type: "success",
          text: this.$tc("calendar.create.notifications.createdEvent")
        });
        this.$router.push({ name: "calendar" });
      } catch (error) {
        this.$notify({
          type: "error",
          text: this.$tc("common.error.generic")
        });
      }
    }

    async updateEvent(body: any) {
      try {
        await apiEvents.updateEvent(this.eventId, body);

        this.$notify({
          type: "success",
          text: this.$tc("calendar.create.notifications.editedEvent")
        });
        this.$router.push({ name: "calendar" });
      } catch (error) {
        this.$notify({
          type: "error",
          text: this.$tc("common.error.generic")
        });
      }
    }

    async mounted() {
      this.eventId = this.$route.params.eventId;

      try {
        this.isPremiumSubscription = (
          await checkSubscriptionPlan(this.ongId)
        ).isPremiumSubscription;

        if (!this.eventId) {
          this.loaded = true;
          return;
        }
        // get event data
        await Promise.all([
          this.getEvent(this.eventId),
          this.getEventImages(this.eventId)
        ]);

        this.loaded = true;
      } catch (error) {
        this.$notify({
          type: "error",
          text: this.$tc("common.error.generic")
        });
      }
    }

    onCancel() {
      this.$router.push({ name: "calendarRead" });
    }

    async onSave() {
      const isNewEvent = !this.eventId;
      const { isPremium, location, link } = this.calendarForm;

      if (isPremium) {
        const {
          isAlreadyPremiumSection,
          premiumEventId
        } = await checkIsAlreadyPremiumSections(this.ongId);

        const inValidSubmit =
          isAlreadyPremiumSection && premiumEventId !== this.eventId;

        if (inValidSubmit) {
          return this.$notify({
            type: "warn",
            text: this.$tc("projects.create.notifications.alreadyPremium")
          });
        }
      }

      const imageUrlToBase64 = await parseFile(
        this.calendarForm.imageUrlToConvert
      );

      const images: any[] = await parseFile(this.calendarForm.imagesToConvert);
      this.calendarForm.location = location || link;

      const body = {
        ...this.calendarForm,
        stock: this.calendarForm.ticketAmount,
        amount: this.calendarForm.ticketPrice,
        imageUrl: imageUrlToBase64[0],
        images: Array.isArray(images) ? images : [images],
        imageUrlToConvert: undefined,
        imagesToConvert: undefined,
        ongId: this.ongId
      };

      if (isNewEvent) this.createEvent(body);
      else this.updateEvent(body);

      inValidatePremiumSectionsCache();
    }

    onCreateTicket() {
      const { ticketAmount, ticketPrice, ticketName } = this.calendarForm;
      if (isNaN(+ticketAmount) || isNaN(+ticketPrice)) return;
      if (!ticketName.trim().length) return;

      const ticket = {
        ticketName: this.calendarForm.ticketName,
        ticketAmount: +this.calendarForm.ticketAmount ?? 0,
        ticketPrice: +this.calendarForm.ticketPrice ?? 0
      };
      this.calendarForm.tickets.push(ticket);

      this.calendarForm.ticketName = "";
      this.calendarForm.ticketAmount = 1;
      this.calendarForm.ticketPrice = 0;
    }

    onRemoveTicket(row: any): void {
      this.calendarForm.tickets = this.calendarForm.tickets.filter(
        ticket => ticket !== row
      );
    }

    confirmDeleteEvent() {
      this.showDeleteModal = true;
    }

    async deleteEvent() {
      if (!this.eventId) return;
      try {
        await apiEvents.deleteEvent(this.ongId, this.eventId);

        if (this.calendarForm.isPremium) {
          inValidatePremiumSectionsCache();
        }

        this.$notify({
          type: "success",
          text: this.$tc("events.create.notifications.removedEvent")
        });
        this.$router.push({ name: "calendarRead" });
      } catch (error) {
        this.$notify({
          type: "error",
          text: this.$tc("common.error.generic")
        });
      }
      this.showDeleteModal = false;
    }

    popUpgradeToPremiumMessage(): void {
      if (this.isPremiumSubscription) return;

      this.$notify({
        type: "info",
        text: this.$tc("common.notifications.upgradeToPremium"),
        ignoreDuplicates: true
      } as NotificationOptions);
    }
  }
